<template>
  <div @click.stop="close">
    <button
      type="button"
      class="btn btn-primary"
      id="button-open-dialog"
      data-bs-toggle="modal"
      data-bs-target="#kt_modal_1"
      hidden
    ></button>
    <div class="modal fade" tabindex="-1" id="kt_modal_1">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-between">
            <h2 class="modal-title">เลือกตำแหน่งรูปภาพ</h2>
            <button
              data-bs-dismiss="modal"
              @click="close"
              type="button"
              class="btn btn-sm"
              id="closeBtn"
            >
              <i
                class="bi bi-x m-0 p-0"
                id="close-btn"
                style="font-size: 25px"
              ></i>
            </button>
          </div>

          <div class="modal-body">
            <cropper
              :src="dataEditItem"
              @change="change"
              :stencil-component="$options.components.Stencil"
            />
          </div>

          <hr class="pt-0 mt-0" style="color: LightGrey" />

          <footer class="mt-2 mb-6 ms-7 row d-flex justify-content-center">
            <div
              class="card-toolbar ps-0 col-sm-10 d-flex justify-content-start"
            >
              <button
                @click="submit"
                type="button"
                class="btn btn-primary me-3"
              >
                บันทึก
              </button>
              <button
                @click="close"
                type="button"
                id="closeDialog"
                class="btn btn-light"
                data-bs-dismiss="modal"
              >
                ยกเลิก
              </button>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";

import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

import Stencil from "./stencil.vue";

export default {
  components: {
    Cropper,
    Stencil,
  },
  props: {
    dialogAddItem: Boolean,
    dataEditItem: String,
  },
  data: () => ({
    isSubmit: false,
    isValid: true,

    imgSize: "",
  }),
  watch: {
    dialogAddItem(val) {
      if (val) {
        this.isValid = true;
        document.getElementById("button-open-dialog").click();
        if (this.dataEditItem) {
          this.form = this.dataEditItem;
          this.searchProduct = this.dataEditItem.product_barcode;
        }
      }
    },
  },
  methods: {
    async submit() {
      this.$emit("submitAddItem", this.imgSize);
      this.close();
      document.getElementById("closeDialog").click();
    },

    change({ coordinates, canvas }) {
      this.imgSize = { coordinates, canvas };
    },

    close() {
      this.$emit("closeDialogAddItem");
    },
  },
};
</script>

<style scoped>
.vue-advanced-cropper__stretcher {
  max-width: 200px;
  max-height: 200px;
}
</style>
